import React, { useState, useEffect } from "react";
import corazon from "../Assets/corazon.svg";
import Spinner from "../components/Spinner/Spinner";
import "./home.scss";
import Me_late from "../SDK";
import Consultorios from "../components/Consultorios/Consultorios";
import ReactTooltip from "react-tooltip";

function Home() {
  const [state, setState] = useState({
    entidades: null,
    entidad: "",
    municipios: null,
    municipio: "",
    fetch: null,
    Consultorios: null,
  });
  const [Especialidades, setEspecialidades] = useState(null);
  const [AniosExperiencia, setAniosExperiencia] = useState(null);
  const [Horarios, setHorarios] = useState(null);
  const [Generos, setGeneros] = useState(null);
  const [hasMunicipio, setHasMunicipio] = useState(false);

  useEffect(() => {
    Me_late.getEntidades().then((response) => {
      setState({ ...state, entidades: Me_late.sortByABC(response.Entidades) });
    });

    Me_late.getEspecialidades().then((response) => {
      setEspecialidades(Me_late.sortByABC(response.Especialidades));
    });
    Me_late.getExperiencia().then((response) => {
      setAniosExperiencia(response.AniosExperiencia);
    });
    Me_late.getHorarios().then((response) => {
      let arr = [];
      response.Horarios.map((horario) => {
        if (horario.Descripcion !== "No se labora") {
          arr.push(horario);
        }
      });
      setHorarios(arr);
    });
    Me_late.getGeneros().then((response) => setGeneros(response.Generos));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  let buscarMunicipio = (e) => {
    setState({
      ...state,
      municipios: null,
      municipio: "",
    });
    Me_late.getMunicipios(e).then((response) => {
      setState({
        ...state,
        municipios: Me_late.sortByABC(response.Municipios),
        municipio: "",
      });
    });
  };

  useEffect(() => {
    if (state.entidad === "") {
      setState({
        ...state,
        municipios: null,
        municipio: "",
      });
    } else {
      if (state.entidad) {
        buscarMunicipio(state.entidad);
      }
      setState({
        ...state,
        municipio: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entidad]);

  let buscarConsultorios = (e) => {
    e.preventDefault();
    setState({
      ...state,
      fetch: true,
    });

    let params = {
      genero: 0,
      entidad: state.entidad,
      municipio:
        state.municipio === "" || state.municipio === "TODOS"
          ? 0
          : state.municipio,
    };

    if (
      state.municipio === "" ||
      state.municipio === "TODOS" ||
      !state.municipio
    ) {
      setHasMunicipio(false);
    } else {
      setHasMunicipio(true);
    }

    Me_late.getConsultorios(params).then((response) => {
      if (response.Consultorios.length > 0) {
        setState({
          ...state,
          fetch: false,
          Consultorios: Me_late.sortByABC(response.Consultorios),
        });
      }
    });
  };

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  return (
    <div className="home">
      <div className="container header">
        <div className="home-titles">
          <h1> Encuentra tu doctor más cercano</h1>
          <h3>Llena los siguientes campos</h3>
        </div>

        <form className="home-form" onSubmit={(e) => buscarConsultorios(e)}>
          {state.entidades ? (
            <>
              <div className="select-group fade-in-fast">
                <div className="home-form-select">
                  <select
                    value={state.entidad}
                    name="entidad"
                    id="entidad"
                    onChange={(e) => onChange(e)}
                    required
                  >
                    <option value="">ESTADO</option>
                    {state.entidades.map((entidad, index) => {
                      return (
                        <option key={index} value={entidad.Clave}>
                          {entidad.Nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div
                  className="home-form-select "
                  data-tip={"Selecciona un estado"}
                  data-tip-disable={state.entidad ? true : false}
                >
                  <select
                    value={state.municipio}
                    name="municipio"
                    id="municipio"
                    onChange={(e) => onChange(e)}
                    disabled={state.municipios ? false : true}
                  >
                    <option value="">Municipio</option>
                    {state.municipios
                      ? state.municipios.map((municipio, index) => {
                          return (
                            <option key={index} value={municipio.Clave}>
                              {municipio.Nombre}
                            </option>
                          );
                        })
                      : null}
                    <option key="TODOS" value="TODOS">
                      TODOS
                    </option>
                  </select>
                </div>
              </div>
              <div
                className="home-button"
                data-tip={"Selecciona un estado"}
                data-tip-disable={state.entidad ? true : false}
              >
                <button
                  type="submit"
                  className="green-btn fade-in-fast"
                  disabled={state.entidad ? false : true}
                  data-tip={"Selecciona un estado"}
                  data-tip-disable={state.entidad ? true : false}
                >
                  Buscar
                </button>
              </div>

              <ReactTooltip place="top" type="dark" effect="solid" />
            </>
          ) : (
            <div className="spin">
              <Spinner />
            </div>
          )}
        </form>
      </div>
      <div className="home-body-research container">
        {state.fetch ? (
          <div className="spener">
            <Spinner />
          </div>
        ) : state.Consultorios ? (
          <Consultorios
            Consultorios={state.Consultorios}
            Especialidades={Especialidades}
            AniosExperiencia={AniosExperiencia}
            Horarios={Horarios}
            Generos={Generos}
            municipios={state.municipios}
            hasMunicipio={hasMunicipio}
          />
        ) : (
          <img src={corazon} alt="corazon" className="fade-in-fast" />
        )}
      </div>
    </div>
  );
}

export default Home;
