import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Select from "../Select";
import "./Consultorios.scss";
import SideDrawer from "./sideDrawer";

import ConsultoriosMobile from "./ConsultoriosMobile";

// import 'react-accessible-accordion/dist/fancy-example.css';

function Clinicas(props) {
  const [state, setState] = useState({
    Colonia: "Colonia",
    Especialidad: "Especialidad",
    Experiencia: "Experiencia",
    Genero: "Genero",
    Municipio: "Municipio",
    Horarios: "Horarios",
  });

  const [Consultorios, setConsultorios] = useState({
    current: props.Consultorios,
    all: props.Consultorios,
  });

  const [Generos, setGeneros] = useState({
    current: props.Generos,
    all: props.Generos,
  });

  const [Especialidades, setEspecialidades] = useState({
    current: props.Especialidades,
    all: props.Especialidades,
  });

  const [AniosExperiencia, setAniosExperiencia] = useState({
    current: props.AniosExperiencia,
    all: props.AniosExperiencia,
  });

  const [Horarios, setHorarios] = useState({
    current: props.Horarios,
    all: props.Horarios,
  });

  const [Colonias, setColonias] = useState({
    current: [],
    all: [],
  });

  const [Municipios, setMunicipios] = useState({
    current: props.municipios,
    all: props.municipios,
  });

  const [mobileNav, setMobileNav] = useState(false);

  let onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  let getColonias = (arr) => {
    var colonias = [];
    arr.map((clinic, index) => {
      return colonias.push(clinic.Colonia.toUpperCase());
    });
    colonias.sort();
    colonias = [...new Set(colonias)];
    setColonias({ current: colonias, all: colonias });
  };

  useEffect(() => {
    getColonias(props.Consultorios);
    filtroGeneros(Consultorios.all);
    filtroEspecialidades(Consultorios.all);
    filtroAniosExperiencia(Consultorios.all);
    filtroMunicipios(Consultorios.all);
    filtroHorarios(Consultorios.all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.Genero.toUpperCase() === "TODOS") {
      filtroEspecialidades(Consultorios.all);
      filtroAniosExperiencia(Consultorios.all);
      filtroMunicipios(Consultorios.all);
      filtroHorarios(Consultorios.all);
      filtroColonia(Consultorios.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Genero]);

  useEffect(() => {
    if (state.Especialidad.toUpperCase() === "TODOS") {
      filtroGeneros(Consultorios.all);
      filtroAniosExperiencia(Consultorios.all);
      filtroMunicipios(Consultorios.all);
      filtroHorarios(Consultorios.all);
      filtroColonia(Consultorios.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Especialidad]);

  useEffect(() => {
    if (state.Experiencia.toUpperCase() === "TODOS") {
      filtroGeneros(Consultorios.all);
      filtroEspecialidades(Consultorios.all);
      filtroMunicipios(Consultorios.all);
      filtroHorarios(Consultorios.all);
      filtroColonia(Consultorios.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Experiencia]);

  useEffect(() => {
    if (state.Municipio.toUpperCase() === "TODOS") {
      filtroGeneros(Consultorios.all);
      filtroEspecialidades(Consultorios.all);
      filtroAniosExperiencia(Consultorios.all);
      filtroHorarios(Consultorios.all);
      filtroColonia(Consultorios.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Municipio]);

  useEffect(() => {
    if (state.Colonia.toUpperCase() === "TODOS") {
      filtroGeneros(Consultorios.all);
      filtroEspecialidades(Consultorios.all);
      filtroAniosExperiencia(Consultorios.all);
      filtroHorarios(Consultorios.all);
      filtroMunicipios(Consultorios.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Colonia]);

  useEffect(() => {
    if (state.Horarios.toUpperCase() === "TODOS") {
      filtroGeneros(Consultorios.all);
      filtroEspecialidades(Consultorios.all);
      filtroAniosExperiencia(Consultorios.all);
      filtroMunicipios(Consultorios.all);
      filtroColonia(Consultorios.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Horarios]);

  useEffect(() => {
    filter();
    // console.log(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  let filter = () => {
    let newArr = Consultorios.all;

    if (state.Genero !== "Genero" && state.Genero !== "TODOS") {
      newArr = newArr.filter(
        (cons) =>
          cons.Medico.Genero.toUpperCase() === state.Genero.toUpperCase()
      );
      filtroEspecialidades(newArr);
      filtroAniosExperiencia(newArr);
      filtroMunicipios(newArr);
      filtroHorarios(newArr);
      filtroColonia(newArr);
    }

    if (
      state.Especialidad !== "Especialidad" &&
      state.Especialidad !== "TODOS"
    ) {
      let especialidades = [];
      newArr.forEach((cons) => {
        cons.Medico.Especialidades.forEach((element) => {
          if (
            element.Especialidad.toUpperCase() ===
            state.Especialidad.toUpperCase()
          ) {
            especialidades.push(cons);
          }
        });
      });
      especialidades = [...new Set(especialidades)];
      newArr = especialidades;
      filtroGeneros(newArr);
      filtroAniosExperiencia(newArr);
      filtroMunicipios(newArr);
      filtroHorarios(newArr);
      filtroColonia(newArr);
    }

    if (state.Experiencia !== "Experiencia" && state.Experiencia !== "TODOS") {
      newArr = newArr.filter(
        (cons) =>
          cons.Medico.AniosExperiencia.toUpperCase() ===
          state.Experiencia.toUpperCase()
      );
      filtroGeneros(newArr);
      filtroEspecialidades(newArr);
      filtroMunicipios(newArr);
      filtroHorarios(newArr);
      filtroColonia(newArr);
    }

    if (
      state.Municipio !== "Municipio" &&
      state.Municipio.toUpperCase() !== "TODOS"
    ) {
      newArr = newArr.filter(
        (cons) => cons.Municipio.toUpperCase() === state.Municipio.toUpperCase()
      );
      filtroGeneros(newArr);
      filtroEspecialidades(newArr);
      filtroAniosExperiencia(newArr);
      filtroHorarios(newArr);
      filtroColonia(newArr);
    }

    if (
      state.Colonia !== "Colonia" &&
      state.Colonia.toUpperCase() !== "TODOS"
    ) {
      newArr = newArr.filter(
        (cons) => cons.Colonia.toUpperCase() === state.Colonia.toUpperCase()
      );

      filtroGeneros(newArr);
      filtroEspecialidades(newArr);
      filtroAniosExperiencia(newArr);
      filtroHorarios(newArr);
      filtroMunicipios(newArr);
    }

    if (
      state.Horarios !== "Horarios" &&
      state.Horarios.toUpperCase() !== "TODOS"
    ) {
      let horas = [];
      newArr.forEach((cons) => {
        cons.Horarios.forEach((element) => {
          if (element.Horario.toUpperCase() === state.Horarios.toUpperCase()) {
            horas.push(cons);
          }
        });
      });
      horas = [...new Set(horas)];
      newArr = horas;
      filtroGeneros(newArr);
      filtroEspecialidades(newArr);
      filtroAniosExperiencia(newArr);
      filtroMunicipios(newArr);
      filtroColonia(newArr);
    }

    setConsultorios({
      ...Consultorios,
      current: newArr,
    });
  };

  let filtroGeneros = (consultorios) => {
    let listaGeneros = Generos.all;
    var Arr = [];
    consultorios.map((consultorio) => {
      listaGeneros.map((genero) => {
        if (consultorio.Medico.Genero === genero.Nombre) {
          Arr.push(genero);
        }
      });
    });
    Arr = [...new Set(Arr)];
    setGeneros({
      ...Generos,
      current: Arr,
    });
  };

  let filtroEspecialidades = (consultorios) => {
    let listaEspecialidades = Especialidades.all;
    var Arr = [];
    consultorios.map((consultorio) => {
      consultorio.Medico.Especialidades.map((especialidadMedico) => {
        listaEspecialidades.map((especialidad) => {
          if (especialidadMedico.Especialidad === especialidad.Nombre) {
            Arr.push(especialidad);
          }
        });
      });
    });
    Arr = [...new Set(Arr)];
    setEspecialidades({
      ...Especialidades,
      current: Arr,
    });
  };

  let filtroAniosExperiencia = (consultorios) => {
    let listaAniosExperiencia = AniosExperiencia.all;

    var Arr = [];
    consultorios.map((consultorio) => {
      listaAniosExperiencia.map((experiencia) => {
        if (consultorio.Medico.AniosExperiencia === experiencia.Descripcion) {
          Arr.push(experiencia);
        }
      });
    });
    Arr = [...new Set(Arr)];

    setAniosExperiencia({
      ...AniosExperiencia,
      current: Arr,
    });
  };

  let filtroMunicipios = (consultorios) => {
    let listaMunicipios = Municipios.all;
    var Arr = [];
    consultorios.map((consultorio) => {
      listaMunicipios.map((municipio) => {
        if (consultorio.Municipio === municipio.Nombre) {
          Arr.push(municipio);
        }
      });
    });
    Arr = [...new Set(Arr)];
    setMunicipios({
      ...Municipios,
      current: Arr,
    });
  };

  let filtroColonia = (consultorios) => {
    let listaColonias = Colonias.all;
    var Arr = [];

    consultorios.map((consultorio) => {
      listaColonias.map((colonia) => {
        if (consultorio.Colonia.toUpperCase() === colonia.toUpperCase()) {
          Arr.push(colonia);
        }
      });
    });

    Arr = [...new Set(Arr)];

    setColonias({
      ...Colonias,
      current: Arr,
    });
  };

  let filtroHorarios = (consultorios) => {
    let listaHorarios = Horarios.all;
    let Arr = [];
    consultorios.map((consultorio) => {
      consultorio.Horarios.map((horarioConsultorio) => {
        listaHorarios.map((horario) => {
          if (horarioConsultorio.Horario === horario.Descripcion) {
            Arr.push(horario);
          }
        });
      });
    });
    Arr = [...new Set(Arr)];
    setHorarios({
      ...Horarios,
      current: Arr,
    });
  };

  // useEffect(() => {
  //   console.log(Generos);

  //   console.log(Generos.current.length);
  // }, [Generos]);

  let setNavOver = (e) => {
    if (e.target.dataset.action) {
      setMobileNav(false);
    }
  };

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = "hidden";
      document.body.scroll = "no";
    } else {
      document.body.style.overflow = "scroll";
      document.body.scroll = "yes";
    }
  }, [mobileNav]);

  return (
    <>
      <div className="consultorios-container fade-in-slow">
        <div
          className={`consultorios-filtros ${
            !props.hasMunicipio ? `` : `hasMunicipio`
          }`}
        >
          <div className="filtro nombre">
            <p>NOMBRE</p>
          </div>
          <div className="filtro genero">
            <Select
              onChange={onChange}
              ArrayList={Generos.current}
              firstItem={"Género"}
              todos={true}
            />
          </div>
          <div className="filtro especialidades">
            <Select
              onChange={onChange}
              ArrayList={Especialidades.current}
              firstItem="Especialidad"
              todos={true}
            />
          </div>
          <div className="filtro experiencia">
            <Select
              onChange={onChange}
              ArrayList={AniosExperiencia.current}
              firstItem="Experiencia"
              todos={true}
            />
          </div>

          {!props.hasMunicipio ? (
            <div className="filtro municipio">
              <Select
                onChange={onChange}
                ArrayList={Municipios.current}
                firstItem="Municipio"
                todos={true}
              />
            </div>
          ) : null}

          <div className="filtro colonia">
            <Select
              onChange={onChange}
              ArrayList={Colonias.current}
              firstItem="Colonia"
              todos={true}
            />
          </div>
          <div className="filtro horarios">
            <Select
              onChange={onChange}
              ArrayList={Horarios.current}
              firstItem="Horarios"
              todos={true}
            />
          </div>
        </div>

        <div
          className={`side-drawer ${!props.hasMunicipio ? `` : `hasMunicipio`}`}
        >
          <div className="side-drawer-hamb" onClick={() => setMobileNav(true)}>
            <div className="hamb-item"></div>
            <div className="hamb-item"></div>
            <div className="hamb-item"></div>
          </div>
          <p>Filtros</p>
        </div>

        <div
          className={`consultorios-body-responsive ${
            !props.hasMunicipio ? `` : `hasMunicipio`
          }`}
        >
          {Consultorios ? (
            <div className="consultorios-mobile">
              <ConsultoriosMobile
                Consultorios={Consultorios.current}
                hasMunicipio={props.hasMunicipio}
              />
            </div>
          ) : null}
        </div>
        <div
          className={`consultorios-body ${
            !props.hasMunicipio ? `` : `hasMunicipio`
          }`}
        >
          {Consultorios
            ? Consultorios.current.map((consultorio, index) => {
                let Medico = consultorio.Medico;
                // console.log(Medico);
                // console.log(consultorio);
                return (
                  <div key={index} className="consultorio">
                    <div className="consultorio-item nombre">
                      <p>
                        {`${Medico.Nombre} ${Medico.ApellidoPaterno} ${Medico.ApellidoMaterno}`}
                      </p>
                    </div>
                    <div className="consultorio-item genero">
                      <p>{Medico.Genero}</p>
                    </div>
                    <div className="consultorio-item especialidades">
                      {Medico.Especialidades.map((especialidad, index) => {
                        return <p key={index}>- {especialidad.Especialidad}</p>;
                      })}
                    </div>
                    <div className="consultorio-item experiencia">
                      <p>{Medico.AniosExperiencia}</p>
                    </div>
                    {!props.hasMunicipio ? (
                      <div className="consultorio-item municipio">
                        <p>{consultorio.Municipio}</p>
                      </div>
                    ) : null}
                    <div className="consultorio-item colonia">
                      <p>{consultorio.Colonia}</p>
                    </div>
                    <div className="consultorio-item">
                      <Link to={`/doctor/${Medico.Clave}`}>
                        <button className="green-btn fade-in-fast">Ver</button>
                      </Link>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {mobileNav ? (
        <>
          <div
            className="overlay fade-in-fast"
            data-action="close"
            onClick={(e) => setNavOver(e)}
          ></div>
        </>
      ) : null}

      <SideDrawer
        onChange={onChange}
        Horarios={Horarios}
        Colonias={Colonias}
        Municipios={Municipios}
        AniosExperiencia={AniosExperiencia}
        Especialidades={Especialidades}
        Generos={Generos}
        hasMunicipio={props.hasMunicipio}
        mobileNav={mobileNav}
        setMobileNav={setMobileNav}
      />
    </>
  );
}

export default Clinicas;
