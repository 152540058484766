import "./encuesta.scss";
import React, { useEffect, useState } from "react";
import Me_late from "../SDK";
import Step1 from "./encuesta/Step1";
import Step2 from "./encuesta/Step2";
import Step3 from "./encuesta/Step3";


const Encuesta = ()=>{
    const [data,setData] = useState({})
    const [step,setStep] = useState(1)

    const nextStep = ()=>{
        setStep(step + 1)
    }

    const submit = async () =>{
        await Me_late.postEncuesta(data)
        setStep(step + 1)
    }

    const renderComponent = () => {
        if(step === 1) return <Step1 nextStep={nextStep} data={data} setData={setData}/>
        if(step === 2) return <Step2 nextStep={submit} data={data} setData={setData}/>
        if(step === 3) return <Step3 />
    }

    return <>
    <img className="banner" src={require("./encuesta/banner.jpg").default}/>
    <img className="banner-mobile" src={require("./encuesta/banner_mobile.jpg").default}/>
        {renderComponent()}
    </>
}

export default Encuesta