import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Me_late from "../../../SDK";
import doc from "../../../Assets/doc.png";
import "./Login.scss";

class AdminLogin extends Component {
  constructor() {
    super();
    this.state = {
      user: "",
      password: "",
      error: null,
      loading: null,
    };
  }

  LogIn(e) {
    e.preventDefault();
    //TODO: call API and login
    this.setState({ loading: true });
    Me_late.login(this.state.user, this.state.password).then((res) => {
      if (!res.error) {
        this.props.history.push("/admin");
      } else {
        this.setState({ error: res.error, loading: false });
      }
    });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value, error: null });
  }

  render() {
    return (
      <form className="form_login" onSubmit={this.LogIn.bind(this)}>
        <div className="doc_img">
          <div className="bg_green"></div>
          <img src={doc} alt="doc_img" />
        </div>
        <div className="form">
          <div className="line"></div>
          <h1>
            Ingresa tus <br /> datos para acceder
          </h1>

          <input
            className="admin_input"
            placeholder="Correo electrónico"
            type="email"
            value={this.state.user}
            name="user"
            onChange={this.handleInputChange.bind(this)}
            required
          />
          <input
            className="admin_input"
            placeholder="Contraseña"
            type="password"
            // type="text"
            value={this.state.password}
            name="password"
            onChange={this.handleInputChange.bind(this)}
            // required
          />
          {this.state.error ? (
            <span style={{ color: "red" }}>{this.state.error}</span>
          ) : null}
          <button type="submit" className="green-btn">
            {this.state.loading ? "Cargando..." : "Ingresar"}
          </button>
        </div>
      </form>
    );
  }
}
export default withRouter(AdminLogin);
