import React, { useState, useEffect } from "react";
import tel_icon from "../Assets/tel-icon.svg";
import email_icon from "../Assets/email.svg";
// import phone_img from '../../../assets/Pantalla_03/phone-img.svg';

function TelefonoFilter(props) {
  // let [Telefono] = useState(props.Telefono);
  let [Telefonos, setTelefonos] = useState([]);

  useEffect(() => {
    let contactos = [];

    if (!props.Telefono.includes("Num:")) {
      let contacto = { Numero: parseInt(props.Telefono) };
      // console.log(contacto);
      contactos.push(contacto);
      setTelefonos(...Telefonos, contactos);
    } else if (props.Telefono.includes("/")) {
      let contactArr = props.Telefono.split("/");
      var contacto = {};
      let arr = [];
      // console.log(contactArr);
      contactArr.map((element) => {
        contacto.Numero = element;
        arr.push(contacto);
      });
      let set = new Set(arr.map(JSON.stringify));
      let datosDeContacto = Array.from(set).map(JSON.parse);
      setTelefonos(...Telefonos, datosDeContacto);
    } else {
      let contactArr = props.Telefono.split(", ");
      // console.log(contactArr);
      contactArr.map((item) => {
        var telNoSpaces = item.split(" ").join("").split("Ext");
        // console.log('telNoSpaces: ', telNoSpaces);
        var contacto = {};

        telNoSpaces.map((item) => {
          if (item.includes("Num:")) {
            contacto.Numero = item.split("Num:").join("");
          } else if (item.includes(":")) {
            contacto.Extension = item.split(":").join("");
          }
        });

        contactos.push(contacto);
        // console.log('contactos: ', contactos);
        let set = new Set(contactos.map(JSON.stringify));
        let datosDeContacto = Array.from(set).map(JSON.parse);
        setTelefonos(...Telefonos, datosDeContacto);
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log('Telefonos: ', Telefonos);
  // }, [Telefonos]);

  return (
    <div className="contacto">
      {Telefonos ? (
        Telefonos.map((tel, index) => {
          // console.log(tel);
          return (
            <div className="contacto-item telfono" key={index}>
              <a href={"tel:" + tel.Numero}>
                <img src={tel_icon} alt="tel_icon" />
              </a>

              <a href={"tel:" + tel.Numero}>
                {tel.Numero}{" "}
                {tel.Extension ? <p>Extensión: {tel.Extension}</p> : null}
              </a>
            </div>
          );
        })
      ) : (
        <label>Sin número telefónico.</label>
      )}
      {props.Email ? (
        <div className="contacto-item telfono">
          <a href={"mailto:" + props.Email}>
            <img src={email_icon} alt="email_icon" />
          </a>

          <a href={"mailto:" + props.Email}>{props.Email}</a>
        </div>
      ) : null}
    </div>
  );
}

export default TelefonoFilter;
