import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Me_late from "../../SDK";
import WomanIcon from "../../Assets/icono_mujer.svg";
import ManIcon from "../../Assets/icono_doctor.svg";
import "../Consultorios/ConsultoriosMobile.scss";

import { AccordionItemPanel } from "react-accessible-accordion";

export default function AdminConsultorio({ Medico }) {
  const [checked, setChecked] = useState(true);
  const [opacity, setOpacity] = useState(false);

  useEffect(() => {
    if (Medico.Estatus === 2) {
      setChecked(false);
      setOpacity(true);
    }
  }, []);

  const handleChange = (e) => {
    // console.log(e);
    if (!e) {
      setChecked(e);
      Me_late.disableDoctor(Medico.Clave).then((res) => {
        if (res) {
          setOpacity(true);
        }
      });
    } else {
      setChecked(e);
      Me_late.activateDoctor(Medico.Clave).then((res) => {
        if (res) {
          setOpacity(false);
        }
      });
    }
  };

  const fixString = (string) => {
    let arr = string.split(" ");
    let stringArr = [];

    arr.forEach((element) => {
      let str = element.toLowerCase();
      stringArr.push(str.charAt(0).toUpperCase() + str.slice(1));
    });

    return stringArr.join(" ");
  };

  return (
    <AccordionItemPanel>
      <div
        className={`fade-in-fast doctor-body admin ${opacity ? "opacity" : ""}`}
      >
        <div className="body-item  icon">
          {Medico.URLFoto ? (
            <img
              style={{ filter: "grayscale(100%)" }}
              src={Medico.URLFoto}
              alt={
                Medico.Nombre + Medico.ApellidoPaterno + Medico.ApellidoMaterno
              }
            />
          ) : Medico.Genero === "Mujer" ? (
            <img src={WomanIcon} alt="WomanIcon" />
          ) : (
            <img src={ManIcon} alt="ManIcon" />
          )}
        </div>
        <div className="body-item  direccion">
          {Medico.Consultorios.length > 0 ? (
            <>
              <p>
                {fixString(Medico.Consultorios[0].Entidad)},{" "}
                {Medico.Consultorios[0].Municipio}
              </p>

              <p>Consultorios registrados: {Medico.Consultorios.length}</p>
            </>
          ) : (
            <p style={{ color: "red" }}>
              Este doctor no tiene consultorios registrados
            </p>
          )}
        </div>

        <div className="body-item  actions">
          <div className="prev-control">
            <p>{!checked ? "Desactivar Doctor" : "Activar Doctor"}</p>

            <Switch
              checked={checked}
              onChange={(e) => handleChange(e, Medico)}
              onColor="#00354F"
              className="react-switch"
              id="normal-switch"
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
        </div>

        <div className="body-item btn">
          <Link to={`admin/doctor/${Medico.Clave}`}>
            <button className="green-btn">Ver mas</button>
          </Link>
        </div>
      </div>
    </AccordionItemPanel>
  );
}
