import React, { Component } from "react";
import "./Modal.scss";

export default class Modal extends Component {
  closeModal = (e) => {
    if (e.target.dataset.action === "close") {
      this.props.setState();
    }
  };

  componentWillUnmount() {
    document.body.style.overflow = "scroll";
    document.body.scroll = "yes";
  }

  componentDidMount() {
    document.body.style.overflow = "hidden";
    document.body.scroll = "no";
  }

  render() {
    return (
      <div
        className="delete-overlay fade-in-fast"
        data-action="close"
        onClick={(e) => this.closeModal(e)}
      >
        <div className="delete-container fade-in-fast">
          {this.props.children}
        </div>
      </div>
    );
  }
}
