import React from "react";
import { Link } from "react-router-dom";
import bayerWhite from "../../Assets/bayer-white.png";
import fb from "../../Assets/icono_fb.svg";
import insta from "../../Assets/icono_ig.svg";

import logoLatimosJuntos from "../../Assets/logo_sigue_latiendo_blanco.svg";

import "./Footer.scss";

function Nabvar() {
  return (
    <div className="footer">
      <div className="top">
        <div className="container">
          <div className="top-links-list">
            <a
              target="_blank"
              href="//siguelatiendo.mx/aviso-de-privacidad"
              className="Link-item"
            >
              Aviso de Privacidad
            </a>{" "}
            <a
              target="_blank"
              href="//siguelatiendo.mx/terminos-y-condiciones-de-uso"
              className="Link-item"
            >
              Condiciones de uso
            </a>{" "}
            <a
              target="_blank"
              href="//siguelatiendo.mx/pie-de-imprenta"
              className="Link-item"
            >
              Pie de Imprenta
            </a>{" "}
            <a target="_blank" href="//siguelatiendo.mx" className="Link-item">
              Home
            </a>{" "}
            <a target="_blank" href="//siguelatiendo.mx/" className="Link-item">
              Sitemap
            </a>{" "}
          </div>

          <div className="top-body">
            <div className="items">
              {/* <p> Date of last update 1 April 2020 </p>
              <p>
              This website is intended to provide information to an
              international audience outside of the US. All pictures used in
              this website show models only
            </p> */}
            </div>
            <img
              src={logoLatimosJuntos}
              alt="logoLatimosJuntos"
              className="logo-latimos"
            />
            <p>
              © {new Date().getFullYear()}, Sigue Latiendo. Todos los derechos
              reservados
            </p>
          </div>

          {/* <div className="bayer-logos">
            <img
              src={logoLatimosJuntos}
              alt="logoLatimosJuntos"
              className="logo-latimos-responsive"
            />
            <div className="bayer-Sponsored">
              <p>Sponsored by</p>
              <img src={bayerWhite} alt="bayerWhite" />
            </div>
          </div> */}
        </div>
      </div>
      <div className="bottom">
        <div className="social-media">
          <a href="https://www.facebook.com/MeLateSerMexicano">
            <img src={fb} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/melatesermexicano/?hl=es-la">
            <img src={insta} alt="instagram" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Nabvar;
