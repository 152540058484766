import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import OneSignal from 'react-onesignal';

// import Me_late from '../SDK';
import Me_late from "../SDK";
import "./Doctor.scss";
import Spinner from "../components/Spinner/Spinner";
import Telefonofilter from "../components/TelefonoFilter";

import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

import leftArrow from "../Assets/left-arrow.svg";
import doctor_hombre from "../Assets/icono_doctor.svg";
import doctor_mujer from "../Assets/icono_mujer.svg";
import manana_off from "../Assets/manana-off.svg";
import manana_on from "../Assets/manana-onn.svg";
import tarde_off from "../Assets/tarde-off.svg";
import tarde_on from "../Assets/tarde-on.png";
import todo_dia_off from "../Assets/todo-dia-off.svg";
import todo_dia_on from "../Assets/todo-dia-on.png";
import direccion from "../Assets/direccion.svg";

function useDoctors(props) {
  const [Doctor, setDoctor] = useState(null);
  const [Id, setId] = useState("");
  const history = useHistory();

  useEffect(() => {
    var url = history.location.pathname.split("/");
    setTimeout(()=>OneSignal.showSlidedownPrompt(),5000);
    setId();
    Me_late.getMedico(url[url.length - 1]).then((response) => {
      if (!response.Medicos) {
        history.push("/");
      } else {
        setDoctor(response.Medicos);
      }
    });
  }, []);

  useEffect(() => {
    console.log(Id);
  }, [Id]);

  // let returnExperiencia = (experiencia) => {
  //   if (experiencia === '3 a 6 años') {
  //     return '+3 años';
  //   } else if (experiencia === '6 a 9 años') {
  //     return '+6 años';
  //   } else if (experiencia === '9 años en adelante') {
  //     return '+9 años';
  //   } else {
  //     return '-3 años';
  //   }
  // };

  let obtenerNombreDia = (numeroDia) => {
    let nombreDia = "";
    switch (numeroDia) {
      case 1:
        nombreDia = "Lunes";
        break;
      case 2:
        nombreDia = "Martes";
        break;
      case 3:
        nombreDia = "Miercoles";
        break;
      case 4:
        nombreDia = "Jueves";
        break;
      case 5:
        nombreDia = "Viernes";
        break;
      case 6:
        nombreDia = "Sábado";
        break;
      case 7:
        nombreDia = "Domingo";
        break;
      default:
        nombreDia = "Lunes";
    }
    return nombreDia;
  };

  let isToday = (day) => {
    var d = new Date();
    var dayWeek = d.getDay();
    if (day === dayWeek) {
      return true;
    } else {
      return false;
    }
  };

  let returnDireccion = (consultorio) => {
    let direccion =
      consultorio.Calle +
      ", " +
      (consultorio.Num ? consultorio.Num + ", " : "") +
      (consultorio.NumExt ? "num. ext. " + consultorio.NumExt + ", " : "") +
      (consultorio.NumInt ? "num. int. " + consultorio.NumInt + ", " : "") +
      (consultorio.Colonia ? consultorio.Colonia + ", " : "") +
      (consultorio.CP ? "CP. " + consultorio.CP + ". " : "") +
      `${fixString(consultorio.Entidad)}, ${consultorio.Municipio}`;

    return direccion;
  };

  let fixString = (string) => {
    let arr = string.split(" ");
    let stringArr = [];

    arr.forEach((element) => {
      let str = element.toLowerCase();
      stringArr.push(str.charAt(0).toUpperCase() + str.slice(1));
    });

    return stringArr.join(" ");
  };

  return (
    <div className="doctor container fade-in-slow">
      <div className="goBack">
        <img src={leftArrow} alt="leftArrow" />
        <Link to="/">Regresar</Link>
      </div>

      {Doctor ? (
        <div className="main-container fade-in-slow ">
          <div className="doctor-container">
            <div className="doctor-imagen">
              {Doctor.URLFoto ? (
                <div className="imagen">
                  <img src={Doctor.URLFoto} alt="" />
                </div>
              ) : Doctor.Genero === "Mujer" ? (
                <div className="imagen">
                  <img src={doctor_mujer} alt="" />
                </div>
              ) : (
                <div className="imagen">
                  <img src={doctor_hombre} alt="" />
                </div>
              )}
            </div>
            <div className="informacion">
              <div className="informacion-header">
                <h1>{Doctor.Genero === "Mujer" ? "Doctora" : "Doctor"}</h1>
                <h1>{`${Doctor.Nombre} ${Doctor.ApellidoPaterno} ${Doctor.ApellidoMaterno}`}</h1>
                <div className="experiencia">
                  {Doctor.Especialidades.length > 0 ? (
                    <h3>{Doctor.Especialidades[0].Especialidad}</h3>
                  ) : null}

                  <span>{` - Experiencia de ${Doctor.AniosExperiencia}`}</span>
                </div>
              </div>
              <div className="consultorio-slides">
                <Carousel
                  showThumbs={false}
                  // showStatus={false}
                  showIndicators={
                    Doctor.Consultorios.length === 1 ? false : true
                  }
                  statusFormatter={(currentItem, total) =>
                    total > 1 ? `${currentItem} de ${total}` : null
                  }
                >
                  {Doctor.Consultorios.map((consultorio, index) => {
                    // console.log(consultorio);
                    return (
                      <div className="consultorio" key={index}>
                        {consultorio.Imagen1 ? (
                          <div className="imagen">
                            <img src={consultorio.Imagen1} alt="" />
                          </div>
                        ) : null}
                        <div className="horarios">
                          {consultorio.Horarios.map((horario, index) => {
                            return (
                              <div
                                key={index}
                                className={`horario ${
                                  isToday(horario.DiaSemana) ? "isToday" : ""
                                }`}
                              >
                                <div className="header">
                                  <p className="dia">
                                    {obtenerNombreDia(horario.DiaSemana)}
                                  </p>
                                  <p className="hr">
                                    {horario.Horario === "Todo el día"
                                      ? "durante: "
                                      : "durante la: "}
                                    <br />

                                    {horario.Horario}
                                  </p>
                                </div>

                                <img
                                  className="icon"
                                  src={
                                    isToday(horario.DiaSemana)
                                      ? horario.Horario === "Todo el día"
                                        ? todo_dia_on
                                        : horario.Horario === "Tarde"
                                        ? tarde_on
                                        : manana_on
                                      : horario.Horario === "Todo el día"
                                      ? todo_dia_off
                                      : horario.Horario === "Tarde"
                                      ? tarde_off
                                      : manana_off
                                  }
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="direccion">
                          <img src={direccion} alt="direccion" />
                          <p>{returnDireccion(consultorio)}</p>
                        </div>

                        <Telefonofilter
                          Telefono={consultorio.Telefono}
                          Email={Doctor.Correo}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="spin">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default useDoctors;
