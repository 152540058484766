import React, { useState, useEffect } from "react";
import WomanIcon from "../../Assets/icono_mujer.svg";
import ManIcon from "../../Assets/icono_doctor.svg";
import "./DoctorPrev.scss";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Me_late from "../../SDK";

function DoctorPrev({ doctor }) {
  // console.log(doctor);

  const [checked, setChecked] = useState(true);
  const [opacity, setOpacity] = useState(false);

  useEffect(() => {
    if (doctor.Estatus === 2) {
      setChecked(false);
      setOpacity(true);
    }
  }, []);

  const handleChange = (e) => {
    // console.log(e);
    if (!e) {
      setChecked(e);
      Me_late.disableDoctor(doctor.Clave).then((res) => {
        if (res) {
          setOpacity(true);
        }
      });
    } else {
      setChecked(e);
      Me_late.activateDoctor(doctor.Clave).then((res) => {
        if (res) {
          setOpacity(false);
        }
      });
    }
  };

  const fixString = (string) => {
    let arr = string.split(" ");
    let stringArr = [];

    arr.forEach((element) => {
      let str = element.toLowerCase();
      stringArr.push(str.charAt(0).toUpperCase() + str.slice(1));
    });

    return stringArr.join(" ");
  };

  return (
    <div className={`doctor-prev ${opacity ? "opacity" : ""}`}>
      <div className="doctor-item icon">
        {doctor.URLFoto ? (
          <img
            src={doctor.URLFoto}
            alt={
              doctor.Nombre + doctor.ApellidoPaterno + doctor.ApellidoMaterno
            }
          />
        ) : doctor.Genero === "Mujer" ? (
          <img src={WomanIcon} alt="WomanIcon" />
        ) : (
          <img src={ManIcon} alt="ManIcon" />
        )}
      </div>
      <div className="doctor-item name">
        <h2>
          {`${doctor.Nombre}  ${doctor.ApellidoPaterno} ${doctor.ApellidoMaterno}`}{" "}
        </h2>
      </div>
      <div className="doctor-item direccion">
        {doctor.Consultorios.length > 0 ? (
          <>
            <h3>
              {fixString(doctor.Consultorios[0].Entidad)},{" "}
              {doctor.Consultorios[0].Municipio}
            </h3>

            <p>Consultorios registrados: {doctor.Consultorios.length}</p>
          </>
        ) : (
          <p style={{ color: "red" }}>
            Este doctor no tiene consultorios registrados
          </p>
        )}
      </div>
      {/* <div>{direccion(doctor)}</div> */}
      <div className="doctor-item actions">
        <Link to={`admin/doctor/${doctor.Clave}`}>
          <button className="green-btn">Ver mas</button>
        </Link>
        <div className="prev-control">
          {!opacity ? "Desactivar Doctor" : "Activar Doctor"}
          <Switch
            checked={checked}
            onChange={(e) => handleChange(e)}
            onColor="#00354F"
            className="react-switch"
            id="normal-switch"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
      </div>
    </div>
  );
}

export default DoctorPrev;
