import React from "react";


const Step3 = () => {


    return <div className="encuesta-container">
        <img className="star" src={require("./icn_star.png").default} alt="star" />
        <h3>¡Muchas gracias por tu tiempo!</h3>
        <p className="tagline">Te invitamos a volver y consultar nuestro directorio para encontrar tu doctor más cercano.</p>
        <button style={{marginTop:40}} className="submit" onClick={()=>window.location ="/"}>Ir al directorio</button>
    </div>

}

export default Step3