import React, { Component, useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Me_late from "./SDK";
import "./App.scss";
import OneSignal from 'react-onesignal';


// Views
import Home from "./views/Home";
import Doctor from "./views/Doctor";
import AdminLogin from "./views/administrador/Login/AdminLogin";
import Admin from "./views/administrador/home/Home";
import EditDoctor from "./views/administrador/doctor/Doctor";
// Componentes
import Navbar from "./components/Navbar/Nabvar";
import Footer from "./components/footer/Footer";
import Spinner from "./components/Spinner/Spinner";
import Encuesta from "./views/Encuesta";

const App = () => {
  useEffect(() => {
    OneSignal.init({
      appId: "a2eb6f04-347b-45a9-b453-650abc211bd2"
    });
  }, []);

  return (
    <div className="latimos-container fade-in-fast">
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/encuesta" component={Encuesta} />
        <Route exact path="/doctor/:id" component={Doctor} />
        <Login exact path="/login" component={AdminLogin} />
        <ProtectedRoute exact path="/admin" component={Admin} />
        <ProtectedRoute
          exact
          path="/admin/doctor/:id"
          component={EditDoctor}
        />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

class ProtectedRoute extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    const isLoggedIn = await Me_late.isLoggedIn();

    if (isLoggedIn) {
      this.setState({ loading: false });
    } else {
      window.location = "/login";
    }
  }
  render() {
    return this.state.loading ? (
      <div
        style={{
          position: "fixed",
          background: "#fff",
          width: "100vw",
          height: "100vh",
          top: 0,
          zIndex: 90,
        }}
      >
        <Spinner />
      </div>
    ) : (
      <this.props.component />
    );
  }
}

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    const isLoggedIn = await Me_late.isLoggedIn();
    // console.log(isLoggedIn);
    if (isLoggedIn) {
      window.location = "/admin";
    } else {
      this.setState({ loading: false });
    }
  }
  render() {
    return this.state.loading ? (
      <div
        style={{
          position: "fixed",
          background: "#fff",
          width: "100vw",
          height: "100vh",
          top: 0,
          zIndex: 90,
        }}
      >
        <Spinner />
      </div>
    ) : (
      <this.props.component />
    );
  }
}

export default withRouter(App);
