import React from 'react';
import Select from '../Select';
import './Consultorios.scss';
import logoLatimosJunto from '../../Assets/logo_sigue_latiendo.svg';
import close from '../../Assets/close.svg';

function useSideDrawer({
  onChange,
  hasMunicipio,
  Generos,
  Especialidades,
  AniosExperiencia,
  Municipios,
  Colonias,
  Horarios,
  mobileNav,
  setMobileNav,
}) {
  return (
    <div
      className={`side-drawer-container ${hasMunicipio ? `` : `hasMunicipio`} ${
        mobileNav ? 'open' : 'close'
      }`}
    >
      <div className="navbar-green"></div>
      <div className="mobile-header">
        <img
          className="logo-latimosJuntos"
          src={logoLatimosJunto}
          alt="logoLatimosJunto"
        />
        <img
          src={close}
          alt="close"
          className="close"
          onClick={() => setMobileNav(false)}
        />
      </div>
      <div className="side-drawer-filtros">
        <h1>Filtro</h1>
        {/* <p>Tipo de doctor</p> */}
        <div className="side-drawer-filtro genero">
          <p>Género</p>
          <Select
            onChange={onChange}
            ArrayList={Generos.current}
            firstItem={'Genero'}
            todos={true}
          />
        </div>
        <div className="side-drawer-filtro especialidades">
          <p>Especialidad</p>
          <Select
            onChange={onChange}
            ArrayList={Especialidades.current}
            firstItem="Especialidad"
            todos={true}
          />
        </div>
        <div className="side-drawer-filtro experiencia">
          <p>Experiencia</p>
          <Select
            onChange={onChange}
            ArrayList={AniosExperiencia.current}
            firstItem="Experiencia"
            todos={true}
          />
        </div>

        {!hasMunicipio ? (
          <div className="side-drawer-filtro municipio">
            <p>Municipio</p>
            <Select
              onChange={onChange}
              ArrayList={Municipios.current}
              firstItem="Municipio"
              todos={true}
            />
          </div>
        ) : null}

        <div className="side-drawer-filtro colonia">
          <p>Colonia</p>
          <Select
            onChange={onChange}
            ArrayList={Colonias.current}
            firstItem="Colonia"
            todos={true}
          />
        </div>
        <div className="side-drawer-filtro horarios">
          <p>Horarios</p>
          <Select
            onChange={onChange}
            ArrayList={Horarios.current}
            firstItem="Horarios"
            todos={true}
          />
        </div>
        <div className="button">
          <button onClick={() => setMobileNav(false)} className="green-btn">
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
}

export default useSideDrawer;
