import React, { useState, useEffect, Suspense, Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

// import Me_late from '../SDK';
import Me_late from "../../../SDK";
import "./Doctor.scss";
import Spinner from "../../../components/Spinner/Spinner";
import Telefonofilter from "../../../components/TelefonoFilter";
import DeleteModal from "./DeleteModal";

import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

import leftArrow from "../../../Assets/left-arrow.svg";
import doctor_hombre from "../../../Assets/icono_doctor.svg";
import doctor_mujer from "../../../Assets/icono_mujer.svg";
import manana_off from "../../../Assets/manana-off.svg";
import manana_on from "../../../Assets/manana-onn.svg";
import tarde_off from "../../../Assets/tarde-off.svg";
import tarde_on from "../../../Assets/tarde-on.png";
import todo_dia_off from "../../../Assets/todo-dia-off.svg";
import todo_dia_on from "../../../Assets/todo-dia-on.png";
import direccion from "../../../Assets/direccion.svg";

const FormDatosGenerales = React.lazy(() => import("./DatosDoctor"));
const Especialidades = React.lazy(() => import("./Especialidades"));
const AgregarClinica = React.lazy(() => import("./AgregarClinica"));

// function useDoctors(props) {
//   const [Doctor, setDoctor] = useState(null);
//   const [fetch, setFetch] = useState(null);
//   const [view, setView] = useState();
//   const [Clinica, setClinica] = useState();
//   const [DeleteClinic, setDeleteClinic] = useState(false);

//   const history = useHistory();

//   useEffect(() => {
//
//   }, []);

//   // let returnExperiencia = (experiencia) => {
//   //   if (experiencia === '3 a 6 años') {
//   //     return '+3 años';
//   //   } else if (experiencia === '6 a 9 años') {
//   //     return '+6 años';
//   //   } else if (experiencia === '9 años en adelante') {
//   //     return '+9 años';
//   //   } else {
//   //     return '-3 años';
//   //   }
//   // };

//   return (

//   );
// }

// export default useDoctors;

class Doctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null,
      delete: false,
    };
  }

  async componentDidMount() {
    await this.getDoctor();
    if (this.props.history.location.pathname.includes("admin")) {
      this.setState({
        isAdmin: true,
      });
    }
  }
  obtenerNombreDia = (numeroDia) => {
    let nombreDia = "";
    switch (numeroDia) {
      case 1:
        nombreDia = "Lunes";
        break;
      case 2:
        nombreDia = "Martes";
        break;
      case 3:
        nombreDia = "Miercoles";
        break;
      case 4:
        nombreDia = "Jueves";
        break;
      case 5:
        nombreDia = "Viernes";
        break;
      case 6:
        nombreDia = "Sábado";
        break;
      case 7:
        nombreDia = "Domingo";
        break;
      default:
        nombreDia = "Lunes";
    }
    return nombreDia;
  };

  getDoctor() {
    var url = this.props.history.location.pathname.split("/");

    const idMedico = url[url.length - 1];
    Me_late.getMedico(idMedico).then((response) => {
      // console.log(response);
      this.setState({
        Doctor: response.Medicos,
      });
    });
  }

  fixString = (string) => {
    let arr = string.split(" ");
    let stringArr = [];

    arr.forEach((element) => {
      let str = element.toLowerCase();
      stringArr.push(str.charAt(0).toUpperCase() + str.slice(1));
    });

    return stringArr.join(" ");
  };

  isToday = (day) => {
    var d = new Date();
    var dayWeek = d.getDay();
    if (day === dayWeek) {
      return true;
    } else {
      return false;
    }
  };

  returnDireccion = (consultorio) => {
    let direccion =
      consultorio.Calle +
      ", " +
      (consultorio.Num ? consultorio.Num + ", " : "") +
      (consultorio.NumExt ? "num. ext. " + consultorio.NumExt + ", " : "") +
      (consultorio.NumInt ? "num. int. " + consultorio.NumInt + ", " : "") +
      (consultorio.Colonia ? consultorio.Colonia + ", " : "") +
      (consultorio.CP ? "CP. " + consultorio.CP + ". " : "") +
      `${this.fixString(consultorio.Entidad)}, ${consultorio.Municipio}`;

    return direccion;
  };

  handleForm(view) {
    if (view !== null) {
      this.setState({
        view: view,
      });
    } else {
      this.setState({
        view: null,
      });
    }
  }
  setDoctor(doctor) {
    this.setState({
      Doctor: doctor,
    });
  }

  async onChangePicture(e) {
    var { Doctor } = this.state;
    this.setState({
      fetch: true,
    });
    var fd = new FormData();
    fd.append("file", e.target.files[0]);
    const result = await Me_late.changeMedicoPicture(fd, Doctor.Clave);

    if (result.Medicos) {
      this.setState({
        fetch: false,
        Doctor: result.Medicos,
      });
    }
  }

  editClinic(clinica) {
    if (clinica) {
      this.setState({
        Clinica: clinica,
      });
    } else {
      this.setState({
        Clinica: null,
      });
    }
    this.handleForm("AgregarClinica");
  }

  handleModal(clinica) {
    this.setState({
      delete: !this.state.delete,
      clinicToDelete: clinica || null,
    });
  }

  eliminar() {
    Me_late.deleteClinic(this.state.clinicToDelete.Clave).then((element) => {
      if (element.Result) {
        this.handleModal();
        this.getDoctor();
      }
    });
  }

  deleteModal() {
    if (this.state.delete) {
      return (
        <DeleteModal
          handleModal={this.handleModal.bind(this)}
          delete={this.eliminar.bind(this)}
        />
      );
    } else {
      return null;
    }
  }

  renderView() {
    switch (this.state.view) {
      case "AgregarClinica":
        return (
          <div
            className="container"
            style={{
              paddingBottom: "2rem",
              paddingTop: "3rem",
            }}
          >
            <AgregarClinica
              handleForm={this.handleForm.bind(this)}
              handleRouteChange={this.props.handleRouteChange}
              setDoctor={this.setDoctor}
              doctor={this.state.Doctor}
              consultorio={this.state.Clinica}
              update={true}
              back={true}
              getDoctor={this.getDoctor.bind(this)}
            />
          </div>
        );
      case "Especialidades":
        return (
          <div
            className="container"
            style={{
              paddingBottom: "2rem",
              paddingTop: "3rem",
            }}
          >
            <Especialidades
              handleForm={this.handleForm.bind(this)}
              setDoctor={this.setDoctor.bind(this)}
              doctor={this.state.Doctor}
              update={true}
            />
          </div>
        );
      case "DatosGenerales":
        return (
          <div
            className="container"
            style={{
              paddingBottom: "2rem",
              paddingTop: "3rem",
            }}
          >
            <FormDatosGenerales
              handleForm={this.handleForm.bind(this)}
              setDoctor={this.setDoctor.bind(this)}
              doctor={this.state.Doctor}
              update={true}
              getDoctor={this.getDoctor.bind(this)}
            />
          </div>
        );
      default:
        var { Doctor, fetch } = this.state;

        return (
          <>
            <div className="_header">
              <div className="goBack">
                <img src={leftArrow} alt="leftArrow" />
                <Link to="/admin">Regresar</Link>
              </div>

              <button onClick={() => Me_late.logout()} className="white-btn">
                Cerrar Sesión
              </button>
            </div>

            <div className="main-container fade-in-slow ">
              <div className="doctor-container">
                <div className="doctor-imagen edit_img">
                  <input
                    style={{ display: "none" }}
                    required
                    type="file"
                    name="pic"
                    id="pic"
                    onChange={(e) => this.onChangePicture(e)}
                    accept="image/*"
                  />
                  {fetch ? (
                    <div className="spin_img">
                      <Spinner />
                    </div>
                  ) : Doctor.URLFoto ? (
                    <div className="imagen">
                      <img src={Doctor.URLFoto} alt="" />
                    </div>
                  ) : Doctor.Genero === "Mujer" ? (
                    <div className="imagen">
                      <img src={doctor_mujer} alt="" />
                    </div>
                  ) : (
                    <div className="imagen">
                      <img src={doctor_hombre} alt="" />
                    </div>
                  )}

                  <label className="green-btn" htmlFor="pic">
                    {fetch ? "Cargando..." : "Editar imagen"}
                  </label>
                </div>

                <div className="informacion">
                  <div className="informacion-header">
                    <div className="edit_btn">
                      <button
                        className="green-btn"
                        onClick={() => this.handleForm("DatosGenerales")}
                      >
                        Editar perfil
                      </button>
                      <button
                        className="green-btn"
                        onClick={() => this.handleForm("Especialidades")}
                      >
                        Editar especialidades
                      </button>
                      <button
                        className="green-btn"
                        onClick={() => this.editClinic()}
                      >
                        Agregar clínica
                      </button>
                    </div>

                    <h1>
                      {this.state.Doctor.Genero === "Mujer"
                        ? "Doctora"
                        : "Doctor"}
                    </h1>
                    <h1>{`${Doctor.Nombre} ${Doctor.ApellidoPaterno} ${Doctor.ApellidoMaterno}`}</h1>
                    <div className="experiencia">
                      {Doctor.Especialidades.length > 0 ? (
                        <h3>{Doctor.Especialidades[0].Especialidad}</h3>
                      ) : null}

                      <span>{` - Experiencia de ${Doctor.AniosExperiencia}`}</span>
                    </div>
                  </div>
                  <div className="consultorio-slides">
                    <Carousel
                      showThumbs={false}
                      // showStatus={false}
                      showIndicators={
                        Doctor.Consultorios.length === 1 ? false : true
                      }
                      statusFormatter={(currentItem, total) =>
                        total > 1 ? `${currentItem} de ${total}` : null
                      }
                    >
                      {this.state.Doctor.Consultorios.map(
                        (consultorio, index) => {
                          // console.log(consultorio);
                          return (
                            <div className="consultorio" key={index}>
                              <div className="edit_btn">
                                <button
                                  className="green-btn"
                                  onClick={() => this.editClinic(consultorio)}
                                >
                                  Editar clínica
                                </button>
                                <button
                                  className="red-btn"
                                  onClick={() => this.handleModal(consultorio)}
                                >
                                  Eliminar
                                </button>
                              </div>
                              {consultorio.Imagen1 ? (
                                <div className="imagen">
                                  <img src={consultorio.Imagen1} alt="" />
                                </div>
                              ) : null}

                              <div className="horarios">
                                {consultorio.Horarios.map((horario, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`_horario ${
                                        this.isToday(horario.DiaSemana)
                                          ? "isToday"
                                          : ""
                                      }`}
                                    >
                                      <div className="header">
                                        <p className="dia">
                                          {this.obtenerNombreDia(
                                            horario.DiaSemana
                                          )}
                                        </p>
                                        <p className="hr">
                                          {horario.Horario === "Todo el día"
                                            ? "durante: "
                                            : "durante la: "}
                                          <br />

                                          {horario.Horario}
                                        </p>
                                      </div>

                                      <img
                                        className="icon"
                                        src={
                                          this.isToday(horario.DiaSemana)
                                            ? horario.Horario === "Todo el día"
                                              ? todo_dia_on
                                              : horario.Horario === "Tarde"
                                              ? tarde_on
                                              : manana_on
                                            : horario.Horario === "Todo el día"
                                            ? todo_dia_off
                                            : horario.Horario === "Tarde"
                                            ? tarde_off
                                            : manana_off
                                        }
                                        alt=""
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="direccion">
                                <img src={direccion} alt="direccion" />
                                <p>{this.returnDireccion(consultorio)}</p>
                              </div>

                              <Telefonofilter
                                Telefono={consultorio.Telefono}
                                Email={Doctor.Correo}
                              />
                            </div>
                          );
                        }
                      )}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  }

  render() {
    // console.log(this.state);
    return this.state.Doctor ? (
      <div style={{ background: "#EFEFEF", flex: 1 }}>
        <div className="doctor container fade-in-slow">
          {Doctor ? (
            <Suspense
              fallback={
                <div className="spin">
                  <Spinner color="#4CD6B0" />
                </div>
              }
            >
              {this.deleteModal()}
              {this.renderView()}
            </Suspense>
          ) : (
            <div className="spin">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className="spin">
        <Spinner />
      </div>
    );
  }
}

export default withRouter(Doctor);
