import React, { useEffect, useState } from 'react';
import Me_late from '../SDK';
import ReactTooltip from 'react-tooltip';

function Select({ ArrayList, firstItem, onChange, todos }) {
  const [newArrayList, setArrayList] = useState(ArrayList);
  const [TooltipDisable, setTooltipDisable] = useState(false);
  const [TooltipText, setTooltipText] = useState('Selecciona un parametro');

  const children = (element) => {
    if (element.Nombre) {
      return element.Nombre.toUpperCase();
    } else if (element.Descripcion) {
      return element.Descripcion.toUpperCase();
    } else {
      return element.toUpperCase();
    }
  };

  useEffect(() => {
    if (ArrayList.length > 0) {
      setArrayList(Me_late.sortByABC(ArrayList));
    }
  }, [ArrayList]);

  useEffect(() => {
    if (newArrayList[0]) {
      let text = 'La búsqueda solo tiene este parámetro';
      let text2 = 'Selecciona un parametro';
      let firstElementLenght = children(newArrayList[0]).length;

      if (newArrayList.length === 1) {
        setTooltipDisable(false);
        if (firstElementLenght > 10) {
          return setTooltipText(children(newArrayList[0]));
        } else {
          setTooltipText(text.toUpperCase());
        }
      } else {
        setTooltipText(text2.toUpperCase());
        setTooltipDisable(true);
      }
    }
  }, [newArrayList]);

  let titleSelect = () => {
    var text = firstItem;
    if (newArrayList.length === 1) {
      text = children(newArrayList[0]);
    }
    return text;
  };

  let getChange = (e) => {
    if (e.target.value.length > 10) {
      setTooltipDisable(false);
      setTooltipText(e.target.value);
    } else {
      setTooltipDisable(true);
    }
    onChange(e);
  };

  return (
    <div data-tip={TooltipText} data-tip-disable={TooltipDisable}>
      <select
        name={firstItem}
        id={firstItem}
        disabled={newArrayList.length <= 1 ? true : false}
        onChange={(e) => {
          getChange(e);
        }}
      >
        <option value={firstItem} className={`${firstItem}-item`}>
          {titleSelect()}
        </option>
        {newArrayList.map((element, index) => {
          return (
            <option
              key={index}
              value={element.Nombre}
              className={`${firstItem}_${children(element).replace(
                /\s/g,
                '_'
              )}`}
              id={`${firstItem}_${children(element).replace(/\s/g, '_')}`}
            >
              {children(element)}
            </option>
          );
        })}
        {todos ? (
          <option value="TODOS" className={`TODOS-item`}>
            TODOS
          </option>
        ) : null}
      </select>
      <ReactTooltip place="top" type="dark" effect="solid" />
    </div>
  );
}

export default Select;
