import React, { useState } from "react";
// import closeIcon from "../../../assets/close.svg";
import Spinner from "../../../components/Spinner/Spinner";
import Modal from "./Modal";

function DeleteModal(props) {
  const [fetch, setFetch] = useState(false);

  function delElement() {
    setFetch(true);
    props.delete();
  }
  // console.log(props);
  return (
    <Modal setState={props.handleModal}>
      {props.autoDel ? (
        <>
          <h2>No te puedes borrar</h2>
          <button
            className="back-button autoDel"
            data-action="close"
            onClick={() => props.handleModal()}
          >
            Ok
          </button>
        </>
      ) : (
        <>
          <h2>¿Estas Seguro?</h2>
          {!fetch ? (
            <div className="delete-btns">
              <button className="back-button" onClick={() => delElement()}>
                Si
              </button>
              <button
                className="back-button"
                data-action="close"
                onClick={() => props.handleModal()}
              >
                No
              </button>
            </div>
          ) : (
            <div className="delete-spinner">
              <Spinner />
            </div>
          )}
        </>
      )}
    </Modal>
  );
}

export default DeleteModal;
