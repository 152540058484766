import React from "react";
import { Spinner } from "react-bootstrap";

function SpinnerCom({ color }) {
  return (
    <Spinner
      animation="grow"
      style={{
        color: color || "#4cd6b0",
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    />
  );
}

export default SpinnerCom;
