import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "./ConsultoriosMobile.scss";
import ConsultorioAdmin from "../DoctorPrev/AdminConsultorio";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function ConusltoriosMobile({ Consultorios, hasMunicipio }) {
  const match = useLocation();
  const [adminUrl, setAdminUrl] = useState(false);

  useEffect(() => {
    match.pathname.includes("admin") ? setAdminUrl(true) : setAdminUrl(false);
  }, [match]);

  return Consultorios.length > 0 ? (
    <Accordion allowZeroExpanded>
      {Consultorios.map((consultorio, index) => {
        var Medico = consultorio.Medico ? consultorio.Medico : consultorio;

        return (
          <AccordionItem key={index} className="consultorio">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="name-doctor">
                  <p>
                    {Medico.Genero === "Hombre" ? "Dr. " : "Dra. "}
                    {Medico.Nombre} {Medico.ApellidoPaterno}{" "}
                    {Medico.ApellidoMaterno}
                  </p>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            {adminUrl ? (
              <ConsultorioAdmin Medico={consultorio} />
            ) : (
              <AccordionItemPanel>
                <div className="fade-in-fast doctor-body">
                  <div className="body-item genero">
                    <p>{Medico.Genero}</p>
                  </div>
                  <div className="body-item especialidades">
                    {Medico.Especialidades.map((espe, index) => {
                      return <p key={index}>{espe.Especialidad}</p>;
                    })}
                  </div>
                  <div className="body-item aniosExperiencia">
                    <p>{Medico.AniosExperiencia}</p>
                  </div>

                  {!hasMunicipio ? (
                    <div className="body-item municipio">
                      <p>{consultorio.Municipio}</p>
                    </div>
                  ) : null}
                  <div className="body-item colonia">
                    <p>{consultorio.Colonia}</p>
                  </div>

                  <Link to={`/doctor/${Medico.Clave}`}>
                    <button className="green-btn ">Ver doctor</button>
                  </Link>
                </div>
              </AccordionItemPanel>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  ) : null;
}

export default ConusltoriosMobile;
