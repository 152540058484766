import React, { useEffect, useState } from "react";

const Step1 = (props) => {
    const [data, setData] = useState()

    const submit = () =>{
        let parent_data = props.data
        parent_data.step1= data
        props.setData(parent_data)
        props.nextStep()
    }
    return (
        <div className="encuesta-container">
            <img className="star" src={require("./icn_star.png").default} alt="star"/>
            <h3>Califica tu visita</h3>
            <p className="tagline">Antes que nada, cuéntanos</p>
            <p className="pregunta">¿Pudiste acudir a tu consulta médica?</p>
            <div className="answers-container">
                <div className={`answer ${data === "Sí acudí a mi consulta" && "active"}`} onClick={()=>{setData("Sí acudí a mi consulta")}}>
                    Sí acudí a mi consulta
                </div>
                <div className={`answer ${data === "No pude acudir a mi consulta" && "active"}`} onClick={()=>{setData("No pude acudir a mi consulta")}}>
                    No pude acudir a mi consulta
                </div>
            </div>
                <button className="submit" onClick={submit} disabled={!data}>Continuar</button>
        </div>
    )

}

export default Step1