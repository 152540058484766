import React, { useEffect, useState } from "react";

const Step2 = (props) => {
    const [data, setData] = useState()
    const [loading,setLoading] = useState(false)

    const submit = async () => {
        setLoading(true)
        let parent_data = props.data
        parent_data.step2 = data
        props.setData(parent_data)
        await props.nextStep()
        setLoading(false)
    }
    return (
        <div className="encuesta-container">
            <img className="star" src={require("./icn_star.png").default} alt="star" />
            {props.data.step1 === "No pude acudir a mi consulta" ?
                <>
                    <h3>Califica tu visita</h3>
                    <p className="tagline">Tu bienestar nos interesa</p>
                    <p className="pregunta">¿Por qué no acudiste a tu cita?</p>
                    <div className="answers-container">
                        <div className={`answer ${data === "No tuve tiempo" && "active"}`} onClick={() => { setData("No tuve tiempo") }}>
                            No tuve tiempo
                        </div>
                        <div className={`answer ${data === "Se me olvidó" && "active"}`} onClick={() => { setData("Se me olvidó") }}>
                            Se me olvidó
                        </div>
                        <div className={`answer ${data === "No me alcanzó dentro de mi presupuesto" && "active"}`} onClick={() => { setData("No me alcanzó dentro de mi presupuesto") }}>
                            No me alcanzó dentro de mi presupuesto
                        </div>
                        <div className={`answer ${data === "Otra..." && "active"}`} onClick={() => { setData("Otra...") }}>
                            Otra...
                        </div>
                    </div>
                </>
                :
                <>
                    <h3>¡Estamos por terminar!</h3>
                    <p className="pregunta">¿Cómo calificarias tu experiencia en la consulta?</p>
                    <div className="answers-container imgs">
                        <div className={`img-answer ${data === "No tuve tiempo" && "active"}`} onClick={() => { setData("Mala") }}>
                        {data !== "Mala" ?
                                <img src={require("./mala.png").default} />
                                :
                                <img src={require("./mala_active.png").default} />
                            }
                            <p>Mala</p>
                        </div>
                        <div className={`img-answer`} onClick={() => { setData("Buena") }}>
                        {data !== "Buena" ?
                                <img src={require("./buena.png").default} />
                                :
                                <img src={require("./buena_active.png").default} />
                            }
                            <p>Buena</p>
                        </div>
                        <div className={`img-answer`} onClick={() => { setData("Excelente") }}>
                            {data !== "Excelente" ?
                                <img src={require("./excelente.png").default} />
                                :
                                <img src={require("./excelente_active.png").default} />
                            }
                            <p>Excelente</p>
                        </div>
                    </div>
                </>
            }
            <button className="submit" onClick={submit} disabled={!data || loading}>Continuar</button>
        </div>
    )

}

export default Step2