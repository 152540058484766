import * as jwt_decode from "jwt-decode";
var serverAddress = process.env.REACT_APP_API_ADDRESS;

export default class Me_late {
  static async request(method, endpoint, payload, jwt, headers) {
    var headers = headers || {
      "Content-Type": "application/json",
    };
    if (jwt) {
      if (localStorage.getItem("at")) {
        await this.isLoggedIn();
      }
      headers["Authorization"] =
        "Bearer " + (localStorage.getItem("at") || jwt);
    }

    if (method !== "get") {
      return fetch(serverAddress + endpoint, {
        method: method,
        headers: headers,
        body: payload instanceof FormData ? payload : JSON.stringify(payload),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          return result;
        });
    } else {
      if (headers.Authorization) {
        return fetch(serverAddress + endpoint, {
          method: method,
          headers: headers,
        })
          .then((response) => {
            if (!response.ok) {
              return response.status;
            }
            return response.json();
          })
          .then((result) => {
            return result;
          });
      } else {
        return fetch(serverAddress + endpoint, {
          method: method,
        })
          .then((response) => {
            if (!response.ok) {
              return response.status;
            }
            return response.json();
          })
          .then((result) => {
            return result;
          });
      }
    }
  }

  static sortByABC(array) {
    let x;
    // console.log(array);
    if (array[0].Nombre) {
      x = array.sort((a, b) => {
        if (a.Nombre || b.Nombre) {
          var textA = a.Nombre.toUpperCase();
          var textB = b.Nombre.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
      });
    } else if (array[0].Medico) {
      x = array.sort((a, b) => {
        if (a.Medico.Nombre || b.Medico.Nombre) {
          var textA = a.Medico.Nombre.toUpperCase();
          var textB = b.Medico.Nombre.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
      });
    } else if (array[0].Descripcion) {
      x = array.sort((a, b) => {
        if (a.Descripcion || b.Descripcion) {
          var textA = a.Descripcion.toUpperCase();
          var textB = b.Descripcion.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
      });
    } else {
      x = array.sort((a, b) => {
        if (a || b) {
          var textA = a.toUpperCase();
          var textB = b.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
      });
    }

    x = [...new Set(x)];
    return x;
  }

  static getEntidades() {
    return this.request("get", "/entidades/activas").then((res) => {
      return res;
    });
  }

  static getAllEntidades() {
    return this.request("get", "/entidades").then((res) => {
      return res;
    });
  }

  static getMunicipios(municipio) {
    return this.request("get", `/municipios/${municipio}/activos`).then(
      (res) => {
        return res;
      }
    );
  }
  static getAllMunicipios(municipio) {
    return this.request("get", `/municipios/${municipio}`).then((res) => {
      return res;
    });
  }

  static getEspecialidades() {
    return this.request("get", `/especialidades`).then((res) => {
      return res;
    });
  }

  static getExperiencia() {
    return this.request("get", `/aniosexperiencia`).then((res) => {
      return res;
    });
  }

  static getConsultorios(payload) {
    return this.request("POST", "/consultorios/busqueda", payload).then(
      (res) => {
        return res;
      }
    );
  }
  static getHorarios() {
    return this.request("get", "/horarios").then((res) => {
      return res;
    });
  }

  static getGeneros() {
    return this.request("get", "/generos").then((res) => {
      return res;
    });
  }

  static getMedico(idMedico) {
    return this.request("get", "/medicos/" + idMedico).then((res) => {
      return res;
    });
  }

  static getAllMedicos(payload) {
    return this.request("get", "/medicos", payload).then((res) => {
      return res;
    });
  }

  //getMedicos trae medicos SOLAMENTE con consultorio
  static getMedicos(payload) {
    return this.request("POST", "/medicos/busqueda", payload).then((res) => {
      return res;
    });
  }

  static postMedico(payload) {
    return this.request("POST", "/medicos", payload, true).then((res) => {
      return res;
    });
  }

  static updateMedico(payload, id) {
    return this.request("PATCH", `/medicos/${id}`, payload, true).then(
      (res) => {
        return res;
      }
    );
  }

  static updateClinic(payload, id) {
    return this.request("PATCH", `/consultorios/${id}`, payload, true).then(
      (res) => {
        return res;
      }
    );
  }

  static updateHorarios(payload, id) {
    return this.request(
      "PATCH",
      `/consultorios/${id}/horarios`,
      payload,
      true
    ).then((res) => {
      return res;
    });
  }

  static postEspecialidad(payload, id) {
    return this.request(
      "POST",
      `/medicos/${id}/especialidad`,
      payload,
      true
    ).then((res) => {
      return res;
    });
  }

  static postClinica(payload, id) {
    return this.request(
      "POST",
      `/medicos/${id}/consultorio`,
      payload,
      true
    ).then((res) => {
      return res;
    });
  }

  static postHorarios(payload, id) {
    return this.request(
      "POST",
      `/consultorios/${id}/horarios`,
      payload,
      true
    ).then((res) => {
      return res;
    });
  }

  static disableDoctor(id) {
    return this.request("DELETE", `/medicos/${id}/desactivar`, {}, true).then(
      (res) => {
        return res;
      }
    );
  }

  static activateDoctor(id) {
    return this.request("get", `/medicos/${id}/activar`, {}, true).then(
      (res) => {
        return res;
      }
    );
  }

  static deleteESP(id, id_especialidad) {
    return this.request(
      "DELETE",
      `/medicos/${id}/especialidad/${id_especialidad}`,
      {},
      true
    ).then((res) => {
      return res;
    });
  }

  static login(username, password) {
    const payload = {
      usuario: username,
      contraseña: password,
    };
    return this.request("POST", "/login", payload).then((res) => {
      if (!res.error) {
        localStorage.setItem("at", res.access_token);
        localStorage.setItem("rt", res.refresh_token);
      }
      return res;
    });
  }

  static isLoggedIn() {
    var atkn = localStorage.getItem("at");
    var rtkn = localStorage.getItem("rt");

    if (atkn && Date.now() <= jwt_decode(atkn).exp * 1000) {
      return true;
    }

    if (rtkn && Date.now() <= jwt_decode(rtkn).exp * 1000) {
      return this.refresh_token();
    }
    // this.logout()
    return false;
  }

  static refresh_token() {
    var rtkn = localStorage.getItem("rt");
    localStorage.setItem("at", "");
    return this.request("POST", "/refresh", null, rtkn)
      .then((res) => {
        if (res.access_token) {
          localStorage.setItem("at", res.access_token);
          return true;
        }
        return false;
      })
      .catch((error) => {
        return false;
      });
  }

  static logout() {
    localStorage.clear();
    window.location.reload();
  }

  static changeMedicoPicture(file, idMedico) {
    const headers = {};
    return this.request(
      "PATCH",
      `/medicos/${idMedico}/picture`,
      file,
      true,
      headers
    ).then((res) => {
      return res;
    });
  }

  static changePictureOfClinic(file, id, numPic) {
    const headers = {};
    return this.request(
      "PATCH",
      `/consultorios/${id}/picture/${numPic}`,
      file,
      true,
      headers
    ).then((res) => {
      return res;
    });
  }

  static deleteClinic(id) {
    return this.request("DELETE", `/consultorios/${id}`, {}, true).then(
      (res) => {
        return res;
      }
    );
  }

  static getAllEstados() {
    return this.request("get", "/entidades").then((res) => {
      return res;
    });
  }

  static getAllAdmins() {
    return this.request("get", "/admins", {}, true).then((res) => {
      return res;
    });
  }

  static getAdmin(id) {
    return this.request("get", `/admins/${id}`, {}, true).then((res) => {
      return res;
    });
  }

  static createAdmin(payload) {
    return this.request("post", `/admins`, payload, true).then((res) => {
      return res;
    });
  }

  static updateAdmin(id, payload) {
    return this.request("PATCH", `/admins/${id}`, payload, true).then((res) => {
      return res;
    });
  }

  static deleteAdmin(id) {
    return this.request("DELETE", `/admins/${id}`, {}, true).then((res) => {
      return res;
    });
  }

  static postEncuesta(payload){
    return this.request("post", `/survey`, payload, true).then((res) => {
      return res;
    });
  }
}
