import React, { Component, Suspense } from "react";
import Me_late from "../../../SDK";
import Spinner from "../../../components/Spinner/Spinner";
import ConsultoriosMobile from "../../../components/Consultorios/ConsultoriosMobile";

import "./Home.scss";
import no_found_img from "../../../Assets/no_encuentra.png";

import Doctor from "../../../components/DoctorPrev/Doctor_prev";

const NewDoctor = React.lazy(() => import("../doctor/DoctorForm"));
const Admin = React.lazy(() =>
  import("../doctor/Administradores/Administradores")
);

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Medicos: [],
      ListaMedicos: [],
      buscar: "",
      view: "",
    };
  }

  componentDidMount() {
    this.getAllMedicos();
  }

  getAllMedicos() {
    Me_late.getAllMedicos().then((res) => {
      this.setState({
        Medicos: res.Medicos,
        ListaMedicos: res.Medicos,
      });
    });
  }

  onChane(e) {
    var name = e.target.value.toLowerCase();
    this.setState({
      buscar: name,
    });
    var arr = [];

    this.state.Medicos.forEach((clinica) => {
      let nombreCompleto = [
        clinica.Nombre,
        clinica.ApellidoPaterno,
        clinica.ApellidoMaterno,
      ]
        .join(" ")
        .toLowerCase();

      if (nombreCompleto.includes(name)) {
        arr.push(clinica);
      }

      this.setState({
        ListaMedicos: arr,
      });
    });
  }

  handleRouteChange(view) {
    if (view !== null) {
      this.setState({ view: view });
    } else {
      this.setState({ view: null });
    }
    this.getAllMedicos();
  }

  renderView() {
    switch (this.state.view) {
      case "NewDoctor":
        return (
          <NewDoctor handleRouteChange={this.handleRouteChange.bind(this)} />
        );
      case "Admin":
        return (
          <Admin
            Administradores={this.state.Administradores}
            handleRouteChange={this.handleRouteChange.bind(this)}
          />
        );
      default:
        return (
          <div className="admin_home">
            <div className="container">
              <div className="controlls">
                <div>
                  <button
                    onClick={() => this.handleRouteChange("")}
                    className="green-btn"
                    disabled
                  >
                    Usuarios
                  </button>
                  <button
                    onClick={() => this.handleRouteChange("Admin")}
                    className="green-btn"
                  >
                    Administradores
                  </button>
                </div>

                <button onClick={() => Me_late.logout()} className="white-btn">
                  Cerrar Sesión
                </button>
              </div>
              <div className="header">
                <input
                  type="text"
                  placeholder="Nombre del doctor..."
                  onChange={(e) => this.onChane(e)}
                  name="Buscar"
                  value={this.state.Buscar}
                  required
                />

                <div>
                  <button
                    onClick={() => this.handleRouteChange("NewDoctor")}
                    className="green-btn"
                  >
                    Añadir nuevo doctor
                  </button>
                </div>
              </div>
              {this.state.ListaMedicos.length === 0 &&
              this.state.buscar !== "" ? (
                <div className="not_found">
                  <h1>No existe coincidencia</h1>
                  <h1 className="blue_text">con tu búsqueda</h1>
                  <img src={no_found_img} alt="no_found_img" />
                </div>
              ) : (
                <>
                  <div className="lista_medicos">
                    {this.state.ListaMedicos.map((medico, idx) => {
                      return <Doctor doctor={medico} key={idx} />;
                    })}
                  </div>
                  <div className="consultorios-mobile">
                    {this.state.ListaMedicos.length > 0 ? (
                      <ConsultoriosMobile
                        Consultorios={this.state.ListaMedicos}
                        hasMunicipio={false}
                      />
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
        );
    }
  }

  render() {
    return (
      <>
        {this.state.Medicos ? (
          <Suspense
            fallback={
              <div className="spin">
                <Spinner />
              </div>
            }
          >
            {this.renderView()}
          </Suspense>
        ) : (
          <div className="spin">
            <Spinner />
          </div>
        )}
      </>
    );
  }
}
