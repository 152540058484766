import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoBayer from "../../Assets/Bayer-Logo-colores.svg";
import logoLatimosJunto from "../../Assets/logo_sigue_latiendo.svg";
import logoPACO from "../../Assets/LOGOPACO.png";
import bigScreen from "../../Assets/img-screen.png";
import "./Navbar.scss";
import Footer from "../footer/Footer";
import close from "../../Assets/close.svg";
import leftArrow from "../../Assets/white-chev.png";

function Nabvar() {
  const [url, setUrl] = useState(false);
  const [adminUrl, setAdminUrl] = useState(false);
  const match = useLocation();

  const [mobileNav, setMobileNav] = useState(false);

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = "hidden";
      document.body.scroll = "no";
    } else {
      document.body.style.overflow = "scroll";
      document.body.scroll = "yes";
    }
  }, [mobileNav]);

  let setNavOver = (e) => {
    if (e.target.dataset.action) {
      setMobileNav(false);
    }
  };

  useEffect(() => {
    match.pathname.includes("doctor") ? setUrl(true) : setUrl(false);
    match.pathname.includes("/admin") || match.pathname.includes("/login")
      ? setAdminUrl(true)
      : setAdminUrl(false);
  }, [match]);

  const isMobileOpen = () => {
    return (
      <>
        {/* <div className={`nav-mobile-container ${mobileNav ? 'open' : 'close'}`}>
          <div className="navbar-green"></div>
          <div className="mobile-header">
            <img
              className="logo-latimosJuntos"
              src={logoLatimosJunto}
              alt="logoLatimosJunto"
            />
            <img
              src={close}
              alt="close"
              className="close"
              onClick={() => setMobileNav(false)}
            />
          </div>
          <div className="mobile-body">
            <div className="mobile-items">
              <Link className="mobile-item white" to="/">
                <p>Home</p>
              </Link>
              <Link className="mobile-item white" to="/">
                <p>Directorio</p>
              </Link>
              <Link className="mobile-item green" to="/">
                <p>Bayer Global</p>
              </Link>
              <Link className="mobile-item green" to="/">
                <p>Contact us</p>
              </Link>
              <Link className="mobile-item green" to="/">
                <p>Location</p>
              </Link>
            </div>

            <Footer />
          </div>
        </div> */}
      </>
    );
  };
  return (
    <>
      {/* <div className="navbar-green">
        <div className="nav-container container col-xl-10 col-lg-11 col-md-12  col-sm-12  ">
          <div className="nav-green-items">
            <div className="regresar">
              <img src={leftArrow} alt="leftArrow" />
              <Link to="/">Regresar</Link>
            </div>
            <div className="handle-navs">
              <Link className="nav-green-item" to="/">
                Bayer Global
              </Link>

              <Link className="nav-green-item" to="/">
                Contact us
              </Link>
              <Link className="nav-green-item" to="/">
                Location
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className="navbar-white">
        <div className="nav-container container   ">
          <div className="logos">
            {/* <img className="logo-bayer" src={logoBayer} alt="logoBayer" /> */}
            <img
              className="logo-latimosJuntos"
              src={logoLatimosJunto}
              alt="logoLatimosJunto"
            />
            <img
              className="logo-latimosJuntos"
              src={logoPACO}
              alt="logo PACO"
            />
          </div>
          {/* <div className="nav-actions">
            <div>
              <Link to="/">HOME</Link>
              <Link to="/">Directorio</Link>
            </div>
          </div> */}
          {/* <div className="nav-hamburguer" onClick={() => setMobileNav(true)}>
            <div className="hamb-item"></div>
            <div className="hamb-item"></div>
            <div className="hamb-item"></div>
          </div> */}
        </div>
      </div>
      {!adminUrl && match.pathname !== "/encuesta" ? (
        <div className={`nav-big-img ${url ? "hasDoctor" : ""} `}>
          <div className="stepper container">
            {url ? (
              <p>
                Home - Formulario - <span>Doctor</span>{" "}
              </p>
            ) : (
              <p>
                Home - <span>Formulario</span>
              </p>
            )}
          </div>

          <img src={bigScreen} alt="" />
        </div>
      ) : null}
      {isMobileOpen()}
      {mobileNav ? (
        <div
          className="overlay fade-in-fast"
          data-action="close"
          onClick={(e) => setNavOver(e)}
        ></div>
      ) : null}
    </>
  );
}

export default Nabvar;
